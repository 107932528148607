import Modal from '@mui/material/Modal';
import React from 'react';
import styles from './popup.module.scss';

interface IProps {
  isVisible: boolean;
  handleClosePopup: () => void;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'full';
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
}

const Popup: React.FC<IProps> = ({ isVisible, handleClosePopup, size = 'md', children, className, style }) => {
  return (
    <Modal open={isVisible} disableAutoFocus onClose={handleClosePopup} className={`${styles.container} ${size === 'full' && styles.fullContainer}`}>
      <div className={`${styles.content_container} ${styles[size]} ${className}`} style={style}>
        {children}
      </div>
    </Modal>
  );
};

export default Popup;
