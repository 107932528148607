import CircularProgress from '@mui/material/CircularProgress';
import React from 'react';
import styles from './spinner.module.scss';

interface IProps {
  className?: string;
  size?: string | number;
}

const Spinner: React.FC<IProps> = ({ className, size }) => {
  return <CircularProgress color="inherit" size={size} className={className} />;
};

export default Spinner;
