import type { NextPage } from 'next';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { Suspense } from 'react';
import { Spinner } from '@/libraries/material-ui';
import wrapper from '@/redux/store';
const HomeIndex = dynamic(() => import('@/containers/home/home-index/home-index'), { suspense: true });
// import { getHomePageSliderData } from 'components/shared/collectionAPIFunctions';
// import { MostLoveDatatype, NewArrivalDatatype } from '@/services/content';

interface IProps {
  homepageLoading?: boolean
}

// const Home: NextPage<IProps> = ({ loveData, newArrivalData }) => (
const Home: NextPage<IProps> = ({homepageLoading}) => (
  <>
    <Head>
      <meta name="google-site-verification" content="5FDF72Qqle3brloL1IZMx7s1epNWXLoPyyjQwND4O2E" />
      <title>Clothing Rental Subscription for Women | FashionPass</title>
      <meta name="description" content="Rent clothing on subscription from FashionPass. Browse styles from today's top brands like For Love & Lemons, Free People, Show Me Your Mumu and ASTR." />
    </Head>
    <Suspense fallback={<Spinner />}>
      <HomeIndex
      homepageLoad={homepageLoading}
      />
    </Suspense>
  </>
);

Home.getInitialProps = wrapper.getInitialPageProps((store) => async (context) => {
  let homepageLoading = false
  if(context?.req?.url?.indexOf('sku_hold_notes_id') > -1){
    homepageLoading = true
  }
  const returnProps: IProps = { homepageLoading };
  return returnProps;
});

export default Home;
