import React from 'react';
import MuiTooltip from '@mui/material/Tooltip';
import styles from './tooltip.module.scss';

interface IProps {
  tooltip: string | React.ReactNode;
  maxWidth?: string;
  elevation?: number;
  children: React.ReactNode;
  disableTooltip?: boolean;
  arrow?: boolean;
  placement?: 'bottom' | 'bottom-end' | 'bottom-start' | 'left-end' | 'left-start' | 'left' | 'right-end' | 'right-start' | 'right' | 'top-end' | 'top-start' | 'top';
  classes?: {
    childContainer?: string;
    tooltip?: string;
    arrow?: string;
    tooltipText?: string;
  };
}

const Tooltip: React.FC<IProps> = ({ tooltip, arrow, maxWidth, classes, elevation = 3, disableTooltip = false, placement = 'bottom', children }) => {
  if (disableTooltip) return <>{children}</>;
  return (
    <MuiTooltip classes={{ popper: styles.popper, tooltip: `${styles.tooltip} ${classes?.tooltip}`, arrow: classes?.arrow }} placement={placement} enterTouchDelay={0} arrow={arrow} title={tooltip !== '' && tooltip !== <></> && <>{typeof tooltip === 'string' ? <p className={`${styles.text} ${classes?.tooltipText}`}>{tooltip}</p> : <>{tooltip}</>}</>}>
      <span className={classes?.childContainer}>{children}</span>
    </MuiTooltip>
  );
};

export default Tooltip;
